<template>
  <a-form-model :model="form" ref="userRulesForm" :rules="rules">
    <a-form-model-item label="Login" prop="login">
      <a-input placeholder="Enter login" :disabled="loading" size="large" v-model="form.login"></a-input>
    </a-form-model-item>
    <a-form-model-item label="Phone number" prop="phone">
      <a-input placeholder="Enter phone number" :disabled="loading" size="large" v-model="form.phone"></a-input>
    </a-form-model-item>
    <hr>
    <a-row>
      <a-col :span="24" class="text-right">
        <a-button @click="closeModal">
          Cancel
        </a-button>
        <a-button type="primary" :disabled="saveIsDisabled" style="margin-left: 10px;" @click="onSubmit" :loading="loading">
          Save
        </a-button>
      </a-col>
    </a-row>
  </a-form-model>
</template>
<script>

import ViewscreenService from '@/services/api/apiViewscreenService'

export default {
  name: 'userModal',
  props: ['segmentId'],
  components: {
  },
  data() {
    return {
      form: {
        login: '',
        phone: '',
      },
      loading: false,
      langOptions: [],
      rules: {
        login: [{ required: true, message: 'Please input user login', trigger: 'blur' }],
        phone: [{ required: true, message: 'Please input user phone number', trigger: 'blur' }],
      },
    }
  },
  computed: {
    saveIsDisabled() {
      return Object.values(this.form).some(value => value === '')
    },
  },
  async created() {
  },
  methods: {
    async onSubmit() {
      const isValid = await new Promise((resolve) => {
        this.$refs.userRulesForm.validate(resolve)
      })
      if (!isValid) {
        return false
      }
      return await this.addUser()
    },
    async addUser() {
      try {
        this.loading = true

        const userResponse = await ViewscreenService.addSegmentAccount(this.segmentId, this.form)
        if (userResponse.data.error) {
          this.$notification.error({
            message: userResponse.data.error.message,
          })
          this.loading = false
          return
        }
        this.$notification.success({
          message: 'User has been successfully added to the segment',
        })
        this.submitUserModal(userResponse.data.data)
      } catch (err) {
        const errorMessage =
        err.response?.data?.error?.message || err.message || 'An unexpected error occurred'

        this.$notification.error({
          message: errorMessage,
        })
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    closeModal () {
      this.$emit('closeUserModal')
    },
    submitUserModal(newUser) {
      this.$emit('addNewUser', newUser)
    },
  },
}
</script>
<style scoped>

</style>
